import { IconButton, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import { useDispatch, useSelector } from "react-redux";
import { ChevronRight, DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { updatedarkmode } from "../features/mode";
import cookies from "../utilities/Cookies";
import Flexbox from "../components/Flexbox";
import requests from "../handlers/requests";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Settings = () => {

    const mode = useSelector(state => state.darkmode.value)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [, setLoading] = useState(false);

    const [, setOpen] = useState(false);
    const [, setSeverity] = useState('success');
    const [, setToastMsg] = useState('');


    const handleDarkMode = (bool) => {
        const obj = { dark: bool }
        dispatch(updatedarkmode(obj))
        cookies.setCookies("mode", bool ? "dark" : "light", 20)
    }

    const logout = () => {
        requests.makeGet("/api/logout", setOpen, setSeverity, setToastMsg, setLoading,
            (_) => {
                cookies.deleteCookies("user")
                navigate('/')
            },
            null
        )
    }

    const general = [
        {
            name: 'Account Status',
            elem: (<>
                <small style={{ opacity: .6, color: 'green' }}>
                    verified
                </small>
            </>)
        },
        {
            name: 'Appearance',
            elem: (<>
                {!mode.dark && (
                    <IconButton onClick={() => handleDarkMode(true)}>
                        <LightModeOutlined style={{ fontSize: '20px' }} />
                    </IconButton>
                )}
                {mode.dark && (
                    <IconButton onClick={() => handleDarkMode(false)}>
                        <DarkModeOutlined style={{ fontSize: '20px' }} />
                    </IconButton>
                )}
            </>)
        }
    ]

    const more = [
        {
            name: 'Refund policy',
            elem: (<>
                <IconButton >
                    <ChevronRight style={{ fontSize: '18px' }} />
                </IconButton>
            </>),
            action: () => {
                window.location.href = 'https://freedomacelerator.trcacademy.digital/refund'
            }
        },
        {
            name: 'Cancelation policy',
            elem: (<>
                <IconButton >
                    <ChevronRight style={{ fontSize: '18px' }} />
                </IconButton>
            </>),
            action: () => {
                window.location.href = 'https://freedomacelerator.trcacademy.digital/refund'
            }
        },
        {
            name: 'Report a bug',
            elem: (<>
                <IconButton >
                    <ChevronRight style={{ fontSize: '18px' }} />
                </IconButton>
            </>),
            action: () => {
                window.location.href = 'mailto:support@trcacademy.digital'
            }
        },
        {
            name: 'Talk to support',
            elem: (<>
                <IconButton >
                    <ChevronRight style={{ fontSize: '18px' }} />
                </IconButton>
            </>),
            action: () => {
                window.location.href = 'mailto:support@trcacademy.digital'
            }
        },
        {
            name: 'Logout',
            elem: (<>
                <IconButton >
                    <ChevronRight style={{ fontSize: '18px' }} />
                </IconButton>
            </>),
            action: logout
        },
    ]

    return (
        <div className="settings-page">
            <Typography>
                Settings
            </Typography>
            <Spacebox padding="10px" />
            <small>General</small>
            <Spacebox padding="5px" />
            <div className="bg" style={{ padding: '20px', borderRadius: '20px' }}>
                {general.map((item, index) => (
                    <div key={index}>
                        <Flexbox justifyContent="space-between" alignItems="center">
                            <span>
                                {item.name}
                            </span>
                            {item.elem}
                        </Flexbox>
                        {(index + 1 !== general.length) && <Spacebox padding="10px" />}
                    </div>
                ))}
            </div>
            <Spacebox padding="10px" />
            <small>More</small>
            <Spacebox padding="5px" />
            <div className="bg" style={{ padding: '20px', borderRadius: '20px' }}>
                {more.map((item, index) => (
                    <div key={index} onClick={item.action}>
                        <Flexbox justifyContent="space-between" alignItems="center">
                            <span>
                                {item.name}
                            </span>
                            {item.elem}
                        </Flexbox>
                        {(index + 1 !== more.length) && <Spacebox padding="10px" />}
                    </div>
                ))}
            </div>
            <Spacebox padding="10px" />
            <p style={{ textAlign: 'center' }}>
                <small style={{ opacity: .6 }}>
                    v1.24
                </small>
            </p>
        </div>
    );
}

export default Settings;