import { IconButton, Typography } from "@mui/material";
import Mode from "../components/styles/Mode";
import Header from "../components/Header";
import Toast from "../components/Toast";
import CustomGrid from "../components/styles/Grid";
import Spacebox from "../components/styles/Spacebox";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../components/styles/Button";
import requests from "../handlers/requests";
import { useNavigate, useParams } from "react-router-dom"
import Flexbox from "../components/Flexbox";
import { Pulsar } from "@uiball/loaders";
import { EditOutlined } from "@mui/icons-material";
import { updateuser } from "../features/users";
import cookies from "../utilities/Cookies";
import { isMobile } from "react-device-detect";

const Password = ({ title }) => {
    document.querySelector("title").innerHTML = title
    const mode = useSelector(state => state.darkmode.value)
    const { email } = useParams()

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [password, setPassword] = useState("")

    const handleClick = () => {
        if (password !== "") {
            setLoading(true)
            requests.makePost('/api/complete-login', { email, password }, setOpen, setSeverity, setToastMsg, setLoading,
                (res) => {
                    const userObj = res.data
                    dispatch(updateuser(userObj))
                    cookies.setCookies("user", JSON.stringify(userObj), 3)
                    navigate('/dashboard')
                },
                null
            )
        } else {
            setToastMsg("Password Mismatch")
            setSeverity("error")
            setOpen(true)
        }
    }

    return (
        <Mode>
            <div className="login-page">
                <Spacebox padding={isMobile ? "20px" : '0px'}>
                    <Header />
                </Spacebox>
                <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
                <Spacebox padding="20px" />
                <CustomGrid grid={isMobile ? 1 : 2} gap={0}>
                    <div style={{ padding: '20px' }}>
                        <Typography variant="h3" sx={isMobile ? { margin: 0, fontSize: '30px' } : { margin: 0 }}>
                            Enter Password
                        </Typography>
                        <Flexbox alignItems="center" >
                            <Typography style={isMobile ? { fontSize: '16px' } : {}}>
                                Sign in for {email}
                            </Typography>
                            <Spacebox padding="5px" />
                            <IconButton onClick={() => {
                                navigate('/')
                            }}>
                                <EditOutlined style={{ fontSize: 20 }} />
                            </IconButton>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <small>Password</small>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Spacebox padding="10px" />
                        <Button
                            backgroundColor="var(--primary)"
                            color="white"
                            borderRadius="10px"
                            padding="15px 0px"
                            handleClick={handleClick}
                            className="fullwidth"
                        >
                            {!loading && "Sign into account"}
                            {loading && (
                                <Flexbox justifyContent="center" alignItems="center">
                                    <Pulsar size={13} color={mode.dark ? "black" : "white"} />
                                </Flexbox>
                            )}
                        </Button>
                    </div>
                </CustomGrid>
            </div>
        </Mode>
    );
}

export default Password;