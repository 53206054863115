import { useState } from "react";
import ArrowPagination from "../components/ArrowPagination";
import useFetch from "../hooks/useFetch";
import Spacebox from "../components/styles/Spacebox";

const Tracked = () => {
    const { data: users, error, isLoading } = useFetch("/api/leads")
    const [paginationData, setPaginationData] = useState(null)

    return (
        <div className="tracked-page">
            {error && (
                <span>An error occured: {error} </span>
            )}
            {isLoading && (
                <span>
                    Loading ....
                </span>
            )}
            {users && (
                <div style={{padding: '20px'}}>
                    <p>
                        Total Users {users.length}
                    </p>
                    <table style={{width: "100%"}}>
                        <thead>
                            <tr>
                                <td style={{ padding: '20px' }}>ID</td>
                                <td style={{ padding: '20px' }}>Fullname</td>
                                <td style={{ padding: '20px' }}>Email</td>
                                <td style={{ padding: '20px' }}>Date</td>
                                <td style={{ padding: '20px' }}>Industry</td>
                            </tr>
                        </thead>
                        {paginationData && <tbody>
                            {paginationData.map((user, index) => (
                                <tr key={index} style={{ background: index % 2 === 0 ? '#f0f0f0' : 'fff' }} >
                                    <td style={{ padding: '20px' }}> {index + 1} </td>
                                    <td style={{ padding: '20px' }}> {user.fullname} </td>
                                    <td style={{ padding: '20px' }}> {user.email} </td>
                                    <td style={{ padding: '20px' }}> {new Date(user.timestamp).toString().substring(0, 21)} </td>
                                    <td style={{ padding: '20px' }}> {(user.industry).substring(0, 16)} </td>
                                </tr>
                            ))}
                        </tbody>}
                    </table>
                    <Spacebox padding="20px" />
                    {users.length > 0 && (
                        <ArrowPagination data={users} setShowData={setPaginationData} limit={50} />
                    )}
                </div>
            )}
        </div>
    );
}

export default Tracked;