import { Typography } from "@mui/material";

const Logo = () => {
    return (
        <div className="logo-text">
            <Typography style={{ lineHeight: "0.5em", fontSize: '23px' }}>
                TRC Academy
            </Typography>
        </div>
    );
}

export default Logo;