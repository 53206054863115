import { Divider, IconButton, Typography } from "@mui/material";
import useFetch from "../hooks/useFetch";
import Spacebox from "../components/styles/Spacebox";
import { Pulsar } from "@uiball/loaders";
import Flexbox from "../components/Flexbox";
import { useSelector } from "react-redux";
import Button from "../components/styles/Button";
import { useState } from "react";
import requests from "../handlers/requests";
import { Close } from "@mui/icons-material";

const Notifications = () => {
    const { data: notifications, isLoading, error } = useFetch('/api/user/notifications')

    const mode = useSelector(state => state.darkmode.value)

    const [openNotif, setOpenNotif] = useState(false)
    const [notif, setNotif] = useState(null)


    const [loading, setLoading] = useState(false);

    const [, setOpen] = useState(false);
    const [, setSeverity] = useState('success');
    const [, setToastMsg] = useState('');

    const updateNotif = (n) => {
        console.log(n.id)
        setLoading(true)
        // update notification in db
        requests.makeGet(`/api/user/notifications/${n.id}`, setOpen, setSeverity, setToastMsg, setLoading,
            (_) => {
                setOpenNotif(true)
                setNotif(n)
            },
            null
        )

    }


    return (
        <div className="notifications-page">
            <Typography>
                Inbox
            </Typography>
            <Spacebox padding="5px" />
            <small>Notifications</small>
            <Spacebox padding="3px" />
            <Divider sx={{ opacity: .6 }} />
            <Spacebox padding="10px" />
            {isLoading && (
                <Flexbox justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
                    <div className="small" style={{ textAlign: "center" }}>
                        <Flexbox justifyContent="center"><Pulsar size={40} speed={0.9} color={mode.dark ? "white" : "black"} /></Flexbox>
                    </div>
                </Flexbox>
            )}
            {loading && (
                <Flexbox justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
                    <div className="small" style={{ textAlign: "center" }}>
                        <Flexbox justifyContent="center"><Pulsar size={40} speed={0.9} color={mode.dark ? "white" : "black"} /></Flexbox>
                    </div>
                </Flexbox>
            )}
            {error && <Flexbox justifyContent="center" alignItems="center">
                <div className="mid" style={{ textAlign: "center" }}>
                    <Spacebox padding="50px" />
                    <Typography variant="h6" className="bold"> There seems to be a problem</Typography>
                    {error.includes("Unauthorised") && (
                        <Button
                            backgroundColor="transparent"
                            color={mode.dark ? "white" : "black"}
                            borderRadius="10px"
                            padding="15px 0px"
                            handleClick={() => {
                                window.location.reload()
                            }}
                            className="fullwidth"
                        >
                            Refresh App
                        </Button>
                    )}
                </div>
            </Flexbox>}
            {(!openNotif && !loading) && <div>
                {notifications && (
                    <div>
                        {notifications.length === 0 && (
                            <div style={{textAlign: 'center'}}>
                                <Spacebox padding="10px" />
                                <small style={{ opacity: .5 }}>You have not notifications</small>
                            </div>
                        )}
                        {notifications.length > 0 && (
                            <div>
                                {notifications.map((notfi, index) => (
                                    <div key={index} onClick={() => {
                                        updateNotif(notfi)
                                    }}>
                                        <Flexbox justifyContent="space-between" alignItems="center">
                                            <Flexbox alignItems="center">
                                                {!(notfi.read) && <div
                                                    style={{ background: 'var(--primary)', width: '10px', height: '10px', borderRadius: '1000px' }}
                                                ></div>}
                                                {!(notfi.read) && <Spacebox padding="5px" />}
                                                <span>
                                                    <b>
                                                        {notfi.title}
                                                    </b>
                                                </span>
                                            </Flexbox>
                                            <small>
                                                <b>
                                                    {(new Date(notfi.timestamp)).toString().substring(0, 10)}
                                                </b>
                                            </small>
                                        </Flexbox>
                                        <Spacebox padding="10px" />
                                        <span>
                                            {notfi.preview}
                                        </span>
                                        <Spacebox padding="10px" />
                                        <Divider sx={{ opacity: .6 }} />
                                        <Spacebox padding="20px" />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>}
            {(openNotif && notif) && <div>
                <Flexbox justifyContent="space-between" alignItems="center">
                    <div>
                        <span>
                            <b>
                                {notif.title}
                            </b>
                        </span>
                        <Spacebox padding="5px" />
                        <small>
                            {(new Date(notif.timestamp)).toString().substring(0, 10)}
                        </small>
                    </div>
                    <IconButton onClick={() => {
                        setOpenNotif(false)
                        setNotif(null)
                        window.location.reload()
                    }}>
                        <Close style={{ fontSize: '18px' }} />
                    </IconButton>
                </Flexbox>
                <Spacebox padding="10px" />
                <div dangerouslySetInnerHTML={{ __html: notif.description }}></div>
                <Spacebox padding="10px" />
            </div>}
        </div>
    );
}

export default Notifications;