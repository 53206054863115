import Flexbox from "./Flexbox";
import Logo from "./Logo";

const Header = () => {

    return (
        <div className="header" style={{padding: '20px'}}>
            <Flexbox alignItems="center" justifyContent="space-between">
                <Logo />
            </Flexbox>
        </div>
    );
}

export default Header;