import { IconButton, Typography } from "@mui/material";
import Mode from "../components/styles/Mode";
import Header from "../components/Header";
import Toast from "../components/Toast";
import CustomGrid from "../components/styles/Grid";
import Spacebox from "../components/styles/Spacebox";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../components/styles/Button";
import requests from "../handlers/requests";
import { useNavigate, useParams } from "react-router-dom"
import Flexbox from "../components/Flexbox";
import { Pulsar } from "@uiball/loaders";
import { EditOutlined } from "@mui/icons-material";
import { updateuser } from "../features/users";
import cookies from "../utilities/Cookies";
import { isMobile } from "react-device-detect";

const Signup = ({ title }) => {
    document.querySelector("title").innerHTML = title
    const mode = useSelector(state => state.darkmode.value)
    const { email } = useParams()

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [phone, setPhone] = useState("")

    const handleClick = () => {
        if (password === confirmPassword) {
            if (firstname !== '' && lastname !== "" && phone !== "") {
                setLoading(true)
                requests.makePost('/api/signup', { firstname, email, lastname, password, phone }, setOpen, setSeverity, setToastMsg, setLoading,
                    (res) => {
                        const userObj = res.data
                        dispatch(updateuser(userObj))
                        cookies.setCookies("user", JSON.stringify(userObj), 3)
                        navigate('/dashboard')
                    },
                    null
                )
            } else {
                setToastMsg("Incomplete data")
                setSeverity("error")
                setOpen(true)
            }
        } else {
            setToastMsg("Password Mismatch")
            setSeverity("error")
            setOpen(true)
        }
    }

    return (
        <Mode>
            <div className="login-page">
                <Spacebox padding="0px">
                    <Header />
                </Spacebox>
                <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
                <Spacebox padding="10px" />
                <CustomGrid grid={isMobile ? 1 : 2} gap={0}>
                    <div style={{ padding: '20px' }}>
                        <Typography variant="h3" sx={isMobile ? { margin: 0, fontSize: '30px' } : { margin: 0 }}>
                            Create An Account
                        </Typography>
                        <Flexbox alignItems="center" >
                            <Typography style={isMobile ? { fontSize: '16px' } : {}}>
                                Sign up for {email}
                            </Typography>
                            <Spacebox padding="5px" />
                            <IconButton onClick={() => {
                                navigate('/')
                            }}>
                                <EditOutlined style={{ fontSize: 20 }} />
                            </IconButton>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <CustomGrid grid={isMobile ? 1 : 2} gap="10px">
                            <div>
                                <small>Firstname</small>
                                <input
                                    type="text"
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    placeholder="Harry"
                                />
                            </div>
                            <div>
                                <small>Lastname</small>
                                <input
                                    type="text"
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                    placeholder="Porter"
                                />
                            </div>
                        </CustomGrid>
                        <Spacebox padding="10px" />
                        <small>Phone</small>
                        <input
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="(879) 567 8910"
                        />
                        <Spacebox padding="10px" />
                        <small>Password</small>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Spacebox padding="2px" />
                        <small style={{opacity: .6}} >Password should be a minimum of 8 characters long</small>
                        <Spacebox padding="10px" />
                        <small>Confrim Password</small>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <Spacebox padding="10px" />
                        <Button
                            backgroundColor="var(--primary)"
                            color="white"
                            borderRadius="10px"
                            padding="15px 0px"
                            handleClick={handleClick}
                            className="fullwidth"
                        >
                            {!loading && "Create Account"}
                            {loading && (
                                <Flexbox justifyContent="center" alignItems="center">
                                    <Pulsar size={13} color={mode.dark ? "black" : "white"} />
                                </Flexbox>
                            )}
                        </Button>
                    </div>
                </CustomGrid>
            </div>
        </Mode>
    );
}

export default Signup;