import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import Flexbox from "../components/Flexbox";
import { Pulsar } from "@uiball/loaders";
import Spacebox from "../components/styles/Spacebox";
import { Divider, IconButton, Typography } from "@mui/material";
import Button from "../components/styles/Button";
import { useState } from "react";
import { ChevronRight, Menu } from "@mui/icons-material";
import { useSelector } from "react-redux";

const Course = () => {
    const { id } = useParams()

    const { data: lessons, isLoading: loading, error } = useFetch(`/api/courses/${id}/lessons`)
    const { data: course } = useFetch(`/api/courses/${id}`)

    const mode = useSelector(state => state.darkmode.value)


    const [openMenu, setOpenMenu] = useState(true)
    const [activeLesson, setActiveLesson] = useState(null)

    return (
        <div className="course-page">
            {loading && <Flexbox justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                <div className="small" style={{ textAlign: "center" }}>
                    <Flexbox justifyContent="center"><Pulsar size={80} speed={0.9} color={mode.dark ? "white" : "black"} /></Flexbox>
                </div>
            </Flexbox>}
            {error && <Flexbox justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                <div className="mid" style={{ textAlign: "center" }}>
                    <Spacebox padding="50px" />
                    <img src="/assets/broken-link.png" alt="" style={{ width: '50px', filter: mode.dark ? "invert(1)" : "invert(0)" }} />
                    <Typography variant="h6" className="bold"> There seems to be a problem</Typography>
                    {error.includes("Unauthorised") && (
                        <Button
                            backgroundColor="transparent"
                            color={mode.dark ? "white" : "black"}
                            borderRadius="10px"
                            padding="15px 0px"
                            handleClick={() => {
                                window.location.reload()
                            }}
                            className="fullwidth"
                        >
                            Refresh App
                        </Button>
                    )}
                </div>
            </Flexbox>}
            {(lessons && course) && (
                <div>
                    {openMenu && (
                        <div className="lesson-menu">
                            <Typography>
                                {course.title}
                            </Typography>
                            <Spacebox padding="5px" />
                            <small>Lessons</small>
                            <Spacebox padding="3px" />
                            <Divider sx={{ opacity: .6 }} />
                            <Spacebox padding="20px" />
                            {lessons.map((lesson, index) => (
                                <div key={index} onClick={() => {
                                    setActiveLesson(lesson)
                                    setOpenMenu(false)
                                    window.scrollTo({ top: 0, behavior: 'smooth' })
                                }} >
                                    <Flexbox justifyContent="space-between" alignItems="center">
                                        <span>
                                            {lesson.title}
                                        </span>
                                        <ChevronRight style={{ fontSize: '16px' }} />
                                    </Flexbox>
                                    <Spacebox padding="5px" />
                                    <Divider sx={{ opacity: .6 }} />
                                    <Spacebox padding="20px" />
                                </div>
                            ))}
                        </div>
                    )}
                    {(!openMenu && activeLesson) && (
                        <div>
                            <div style={{ position: 'sticky', top: '-22px', padding: '10px 0px' }} className="lesson-header" >
                                <Flexbox alignItems="center">
                                    <IconButton onClick={() => {
                                        setOpenMenu(true)
                                    }}>
                                        <Menu />
                                    </IconButton>
                                    <Spacebox padding="10px" />
                                    <Typography>
                                        {activeLesson.title}
                                    </Typography>
                                </Flexbox>
                            </div>
                            <Spacebox padding="10px" />
                            {activeLesson.video !== 'null' && <div dangerouslySetInnerHTML={{ __html: activeLesson.video }} className="video-holder" style={{ background: '#000', height: '250px' }}></div>}
                            {activeLesson.video !== 'null' && <Spacebox padding="10px" />}
                            <div dangerouslySetInnerHTML={{ __html: activeLesson.description }}></div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Course;