import { Avatar, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import Flexbox from "../components/Flexbox";
import Spacebox from "../components/styles/Spacebox";
import { ArrowBack, Check, Close, Replay } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Button from "../components/styles/Button";
import CustomGrid from "../components/styles/Grid";
import cookies from "../utilities/Cookies";
import intlTelInput from "../utilities/util";
import { ReactMediaRecorder } from 'react-media-recorder'
import String from "../utilities/Strings";
import { isMobile } from "react-device-detect";
import { DotPulse } from "@uiball/loaders";


// const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);


const VideoPreview = ({ stream, status }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);
    if (!stream) {
        return null;
    }
    return (
        <div className={"videoPreview " + status}>
            <video ref={videoRef} playsInline style={{ width: '500px', height: '400px' }} autoPlay />
        </div>
    );
};

const VideoPrePreview = ({ stream }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream;
        }
    }, [stream]);
    if (!stream) {
        return null;
    }
    return (
        <div className={"videoPreview pre-preview"}>
            <video ref={videoRef} playsInline style={{ height: '400px', borderRadius: '20px', aspectRatio: '16/9' }} autoPlay />
        </div>
    );
};

const VideoResult = ({ url }) => {

    return (
        <div className="videoResult">
            <video src={url} playsInline muted autoPlay loop style={{ width: '500px', height: '400px' }} />
        </div>
    )
}

const MetaMap = ({title}) => {
    document.querySelector('title').innerHTML = title
    const navigate = useNavigate()
    const [address, setAddress] = useState("")
    const [state, setState] = useState("")
    const [city, setCity] = useState("")
    const [zip, setZip] = useState("")
    const [country, setCountry] = useState("")
    const [ssn, setSsn] = useState("")
    const [countries, setCountries] = useState([])
    const [bankStatement, setBankStatement] = useState(null)
    const [step, setStep] = useState(0) 


    const stringify_user = cookies.getCookies("user")

    const [prePreviewStream, setPrePreviewStream] = useState(null);
    const [videoCommand, setVideoCommand] = useState("Click the button below to start");
    const [video, setVideo] = useState(null)
    const [loading, setLoading] = useState(false)

    const [govID, setGovID] = useState("International Passport")
    const [id_front, setIdFront] = useState(null)
    const [id_back, setIdBack] = useState(null)

    const [user, setUser] = useState(null)

    const [error, setError] = useState(false)
    const [msg, setMsg] = useState('')

    // const stringify_user = cookies.getCookies('user')

    const id_images = {
        "International Passport": {
            name: "International Passport",
            front: "/assets/passport.png"
        },
        "Voter's Card": {
            name: "Voter's Card",
            front: "assets/national_id_front.png",
            back: "assets/national_id_back.png"
        },
        "National ID Card": {
            name: "National ID Card",
            front: "assets/national_id_front.png",
            back: "assets/national_id_back.png"
        },
        "Driver's Licence": {
            name: "Driver's Licence",
            front: "assets/dl_front.png",
            back: "assets/dl_back.png"
        },
    }

    const getFlag = country => {
        let user_country = intlTelInput.countries.filter(cnty => cnty.name === country)
        return "/flags/" + user_country[0].cca2 + ".png"
    }

    const showError = msg => {
        setError(true)
        setMsg(msg)
        setTimeout(() => {
            setMsg('')
            setError(false)
        }, 5000);
    }

    const handleFile = (file) => {
        if (file) {
            if (file.type === "application/pdf") {
                setBankStatement(file)
            } else {
                showError("Provide a pdf")
            }
        } else {
            setBankStatement(null)
        }
    }

    const handleIDFileFront = (file) => {
        if (file) {
            if ((file.type).includes('image/png') || (file.type).includes('image/jpeg')) {
                setIdFront(file)
            } else {
                showError("Supports png or jpeg")
            }
        } else {
            setIdFront(null)
        }
    }

    const handleIDFileBack = (file) => {
        if (file) {
            if ((file.type).includes('image/png') || (file.type).includes('image/jpeg')) {
                setIdBack(file)
            } else {
                showError("Supports png or jpeg")
            }
        } else {
            setIdBack(null)
        }
    }

    const handleIdSubmit = () => {
        if ((govID === "International Passport" && id_front !== null) || (id_front !== null && id_back !== null)) {
            setLoading(true)

            const formdata = new FormData()

            formdata.append('id_front', id_front)
            if (govID !== "International Passport")
                formdata.append('id_back', id_back)
            formdata.append('gov_id', govID)
            formdata.append('bank_statement', bankStatement)
            formdata.append('address', address)
            formdata.append('city', city)
            formdata.append('zip', zip)
            formdata.append('state', state)
            formdata.append('country', country)
            formdata.append('uid', user.id)

            fetch(`/api/upload-identity`, {
                mode: 'cors',
                method: 'POST',
                body: formdata
            }).then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setLoading(false)
                        setStep(3.5)
                    } else {
                        showError(res.msg)
                        console.log(res.error)
                        setLoading(false)
                    }
                }).catch(err => {
                    showError("An error occured, try again")
                    console.log(err)
                    setLoading(false)
                })

        } else {
            showError("Please provide your " + govID)
        }
    }

    const handleSubmitSSN = () => {
        if(ssn !== '') {
            setLoading(false)
            fetch("/api/user/update", {
                method: 'POST',
                credentials: "include",
                headers: {"Content-Type": "application/json", "Accept": "application/json", "Origin": "http://localhost:3000"},
                body: JSON.stringify({ssn})
            }).then(res => res.json())
            .then(res => {
                if(res.success) {
                    setLoading(false)
                    setStep(4)
                }else{
                    setLoading(false)
                    showError(res.msg)
                }
            }).catch(err => {
                showError("An error occured, Please try again")
                console.log(err)
            })
        }else {
            showError("Invalid SSN")
        }
    }

    const verifyStep2 = () => {
        if (address !== "" && state !== "" && city !== "" && zip !== "" && bankStatement !== null) {
            setStep(2)
        } else {
            showError("Enter all information")
        }
    }

    const handleStepFive = () => {
        // Condition => window.navigator.getUserMedia && window.MediaDevices
        if (window.navigator.mediaDevices) {
            // camera access supported
            window.navigator.mediaDevices.getUserMedia({ video: true })
                .then(stream => {
                    setPrePreviewStream(stream)
                    setStep(5)

                    let engine = window.setInterval(() => {
                        // check if prePreviewStream is set to null
                        if (prePreviewStream === "") {
                            stream.getTracks().forEach(track => track.stop()) //close the mediaStream
                            window.clearInterval(engine)
                        }
                    }, 4000);
                }).catch(err => {
                    console.log(err)
                    if (window.confirm("Camera access needed, Try again?"))
                        handleStepFive()
                    else
                        showError("Operation aborted")
                })
        } else {
            // camera access not supported
            showError("Camera access not supported")
        }
    }

    // handle video 
    const handleRecording = (startRecording, stopRecording) => {
        startRecording();
        setVideoCommand("Make a circular shape with your nose 2 times.")
        setTimeout(() => {
            setVideoCommand("Keep your head still and smile")
            setTimeout(async () => {
                setVideoCommand("Preview video")
                console.log("value")
                const videoBlob = await stopRecording(); // Get the Blob
                console.log(videoBlob)
                setVideo(videoBlob)
            }, 8000);
        }, 8000);
    }

    const handleVideoUpload = () => {
        setStep(6)
        console.log(video)

        const formdata = new FormData()

        formdata.append("video", video)
        formdata.append("uid", user.id)

        fetch(`/api/upload-video`, {
            mode: 'cors',
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(res => {
                if (res.success) {
                    navigate('/dashboard')
                } else {
                    showError(res.msg)
                    console.log(res.error)
                    setStep(5)
                }
            }).catch(err => {
                console.log(err)
                showError("An error occured")
                setStep(5)
            })
    };

    const getCountries = () => {
        let countries = intlTelInput.countries
        let arr = []
        for (let x in countries) {
            arr.push(countries[x].name)
        }
        setCountries(arr)
    }


    useEffect(() => {
        if (stringify_user.length < 10) {
            // navigate('/')
        } else {
            if (!(window.navigator.mediaDevices)) {
                alert("Camera not supported, please use a WebRTC. e.g Chrome")
                navigate('/dashboard')
            }else {
                setUser(JSON.parse(stringify_user))
                // get countries
                getCountries()
            }
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="metamap-body" style={{ overflowY: 'scroll', height: "100vh", display: 'flex' }}>
            {user && <div className="" style={{ borderRadius: '20px', width: isMobile ? '100%' : 450, background: 'white', margin: isMobile ? "auto" : "0px auto", height: 'fit-content' }}>
                <Spacebox padding="20px" >
                    <Flexbox alignItems="center" justifyContent="space-between">
                        <img src="/assets/lang.png" style={{ width: 50 }} alt="metamap" />
                        <img src="/assets/metamap.png" style={{ width: 150 }} alt="metamap" />
                        <IconButton onClick={() => { navigate("/dashboard") }}>
                            <Close style={{ opacity: .5 }} />
                        </IconButton>
                    </Flexbox>
                    <Spacebox padding="10px" />
                    {error && <div style={{ background: '#ff000020', padding: '15px', textAlign: 'center', margin: '10px auto', borderRadius: '10px', position: 'fixed', bottom: "20px", width: "200px", left: "calc(50% - (200px/2))", zIndex: 100 }}>
                        <span className="red-text">{msg}</span>
                    </div>}
                    {step === 0 && <div>
                        <Typography textAlign="center" style={{ fontSize: 25, fontWeight: 700 }}>
                            Let's verify your identity
                        </Typography>
                        <Typography textAlign="center" style={{ opacity: .5 }}>
                            To get verified, you will need to:
                        </Typography>
                        <Spacebox padding={isMobile ? "20px" : "40px"} />
                        <Spacebox padding={isMobile ? "10px" : "30px"}>
                            <Flexbox alignItems="center">
                                <img src="/svgs/document_upload.svg" style={{ width: 80 }} alt="document" />
                                <Spacebox padding="5px" />
                                <Typography>
                                    Upload photos of documents proving your identity and address
                                </Typography>
                            </Flexbox>
                            <Spacebox padding="10px" />
                            <Flexbox alignItems="center">
                                <img src="/svgs/selfie_verification.svg" style={{ width: 80 }} alt="selfie" />
                                <Spacebox padding="5px" />
                                <Typography>
                                    Record a short selfie video
                                </Typography>
                            </Flexbox>
                        </Spacebox>
                        <Spacebox padding={isMobile ? "20px" : "40px"} />
                        <Button className="fullwidth" style={{ borderRadius: '5px', backgroundColor: '#242A3A', padding: '15px 0px' }} handleClick={() => setStep(1)}>
                            <span className="white-text" style={{color: 'white'}}>
                                Start
                            </span>
                        </Button>
                        <Spacebox padding="5px" />
                        <Typography textAlign="center" style={{ lineHeight: '1.0em' }}>
                            <small>By clicking the "Start" button you agree to our <a rel="noreferrer" target="_blank" href="https://mercuryo.io/legal/terms/"><u>User Terms and Conditions and our Platform Privacy Policy</u></a></small>
                        </Typography>
                    </div>}
                    {step === 1 && <div>
                        <Typography textAlign="center" style={{ fontSize: 25, fontWeight: 700 }}>
                            Let's verify your address
                        </Typography>
                        <Spacebox padding="20px" />
                        <Spacebox padding="5px">
                            <small>Address</small>
                        </Spacebox>
                        <input
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            type="text"
                            placeholder="Enter your address"
                        />
                        <Spacebox padding="10px" />
                        <Spacebox padding="5px">
                            <small>State</small>
                        </Spacebox>
                        <input
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            type="text"
                            placeholder="Enter your state"
                        />
                        <Spacebox padding="10px" />
                        <CustomGrid gap="20px" grid={2}>
                            <div>
                                <Spacebox padding="5px">
                                    <small>City</small>
                                </Spacebox>
                                <input
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    type="text"
                                    placeholder="Enter your city"
                                />
                            </div>
                            <div>
                                <Spacebox padding="5px">
                                    <small>Zip code</small>
                                </Spacebox>
                                <input
                                    value={zip}
                                    onChange={(e) => setZip(e.target.value)}
                                    type="text"
                                    placeholder="Zip code"
                                />
                            </div>
                        </CustomGrid>
                        <Spacebox padding="10px" />
                        <Spacebox padding="5px">
                            <small>Country</small>
                        </Spacebox>
                        <select
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                        >
                            {countries.map((country, index) => (
                                <option key={index} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                        <Spacebox padding="10px" />
                        <Spacebox padding="5px">
                            <small>Upload a recent bank statement/Utility bill</small>
                        </Spacebox>
                        <div style={{ position: 'relative' }}>
                            <div className="psuedo-file-input" style={{ background: '#f8f8f8', padding: "15px 10px", borderRadius: '10px', minHeight: 20 }}>
                                <Flexbox alignItems="center" justifyContent="space-between">
                                    <Typography style={{ opacity: '.7', fontSize: '16px' }}>
                                        {bankStatement ? String.shorten(bankStatement.name, 20) : "Select a file to upload"}
                                    </Typography>

                                    <Typography className="blue-text" style={{ fontSize: '16px' }}>
                                        upload file
                                    </Typography>
                                </Flexbox>
                            </div>
                            <input
                                onChange={(e) => handleFile(e.target.files[0])}
                                type="file"
                                placeholder="Select a file to upload"
                                style={{ position: 'absolute', top: 0, left: 0, opacity: 0 }}
                            />
                        </div>
                        <Spacebox padding="5px">
                            <small>This serves as your proof of address</small>
                        </Spacebox>
                        <Spacebox padding="20px" />
                        <Button className="fullwidth" style={{ borderRadius: '5px', backgroundColor: '#242A3A', padding: '15px 0px', color: 'white' }} handleClick={() => verifyStep2()}>
                            <span className="white-text">
                                Submit Details
                            </span>
                        </Button>
                    </div>}
                    {step === 2 && <div>
                        <Typography textAlign="center" style={{ fontSize: 25, fontWeight: 700 }}>
                            Let's verify your identity
                        </Typography>
                        <Spacebox padding="20px" />
                        <div style={{ background: '#f8f8f8', padding: "15px 10px", borderRadius: '10px' }}>
                            <Flexbox alignItems="center">
                                <Avatar style={{ width: 30, height: 30 }} src={getFlag(country)} alt={country} />
                                <Spacebox padding="10px" />
                                <Typography>
                                    {country}
                                </Typography>
                            </Flexbox>
                            <Spacebox padding="10px" />
                            <FormControl>
                                <FormLabel id="gov-id" style={{ opacity: .5 }}>Official Government Issued IDs</FormLabel>
                                <Spacebox padding="5px" />
                                <RadioGroup
                                    aria-labelledby="gov-id"
                                    defaultValue={govID}
                                    name="radio-buttons-group"
                                    value={govID}
                                    onChange={(e) => {
                                        setGovID(e.target.value)
                                    }}
                                >
                                    <FormControlLabel value="International Passport" control={<Radio />} label="International Passport"/>
                                    <FormControlLabel value="Driver's Licence" control={<Radio />} label="Driver's License" />
                                    <FormControlLabel value="National ID Card" control={<Radio />} label="National ID Card" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <Spacebox padding="10px" />
                        <Button className="fullwidth" style={{ borderRadius: '5px', backgroundColor: '#242A3A', padding: '15px 0px' }} handleClick={() => setStep(3)}>
                            <span className="white-text">
                                Proceed to submitting my {govID}
                            </span>
                        </Button>
                    </div>}
                    {step === 3 && <div>
                        <Typography textAlign="center" style={{ fontSize: 25, fontWeight: 700 }}>
                            {govID}
                        </Typography>
                        <Typography textAlign="center" style={{ opacity: .5 }}>
                            {country}
                        </Typography>
                        <Spacebox padding="10px" />
                        <IconButton onClick={() => setStep(2)}>
                            <ArrowBack style={{ opacity: .8 }} />
                        </IconButton>
                        <Spacebox padding="5px" />
                        {govID === "International Passport" && <div>
                            <small>Sample Shot:</small>
                            <Spacebox padding="5px" />
                            <div style={{ textAlign: 'center' }}>
                                <img src={id_front ? URL.createObjectURL(id_front) : id_images[govID].front} alt={govID} style={{ width: 150, filter: `blur(${ id_front ? "0px" : "1px"}) drop-shadow(0px 0px 20px #00000020)` }} />
                            </div>
                            <Spacebox padding="10px" />
                            <Spacebox padding="5px">
                                <small>Upload a clear picture of your {govID}</small>
                            </Spacebox>
                            <div style={{ position: 'relative' }}>
                                <div className="psuedo-file-input" style={{ background: '#f8f8f8', padding: "15px 10px", borderRadius: '10px', minHeight: 20 }}>
                                    <Flexbox alignItems="center" justifyContent="space-between">
                                        <Typography style={{ opacity: '.7', fontSize: '16px' }}>
                                            {id_front ? String.shorten(id_front.name, 20) : "Select a file to upload"}
                                        </Typography>

                                        <Typography className="blue-text" style={{ fontSize: '16px' }}>
                                            upload image
                                        </Typography>
                                    </Flexbox>
                                </div>
                                <input
                                    onChange={(e) => handleIDFileFront(e.target.files[0])}
                                    type="file"
                                    placeholder="Select a file to upload"
                                    style={{ position: 'absolute', top: 0, left: 0, opacity: 0 }}
                                />
                            </div>
                        </div>}
                        {govID !== "International Passport" && <div>
                            <small>Sample Front Shot:</small>
                            <Spacebox padding="5px" />
                            <CustomGrid grid={2} gap="20px">
                                <div style={{ textAlign: 'center' }}>
                                    <img src={id_front ? URL.createObjectURL(id_front) : id_images[govID].front} alt={govID} style={{ width: 150, filter: `blur(${ id_front ? "0px" : "1px"}) drop-shadow(0px 0px 20px #00000020)` }} />
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={id_back ? URL.createObjectURL(id_back) : id_images[govID].back} alt={govID} style={{ width: 150, filter: `blur(${ id_back ? "0px" : "1px"}) drop-shadow(0px 0px 20px #00000020)` }} />
                                </div>
                            </CustomGrid>
                            <Spacebox padding="10px" />
                            <Spacebox padding="5px">
                                <small>Upload a clear front photo of your {govID}</small>
                            </Spacebox>
                            <div style={{ position: 'relative' }}>
                                <div className="psuedo-file-input" style={{ background: '#f8f8f8', padding: "15px 10px", borderRadius: '10px', minHeight: 20 }}>
                                    <Flexbox alignItems="center" justifyContent="space-between">
                                        <Typography style={{ opacity: '.7', fontSize: '16px' }}>
                                            {id_front ? String.shorten(id_front.name, 20)  : "Select a file to upload"}
                                        </Typography>

                                        <Typography className="blue-text"  style={{ fontSize: '16px' }}>
                                            upload image
                                        </Typography>
                                    </Flexbox>
                                </div>
                                <input
                                    onChange={(e) => handleIDFileFront(e.target.files[0])}
                                    type="file"
                                    placeholder="Select a file to upload"
                                    style={{ position: 'absolute', top: 0, left: 0, opacity: 0 }}
                                />
                            </div>
                            <Spacebox padding="10px" />
                            <Spacebox padding="5px">
                                <small>Upload a clear back photo of your {govID}</small>
                            </Spacebox>
                            <div style={{ position: 'relative' }}>
                                <div className="psuedo-file-input" style={{ background: '#f8f8f8', padding: "15px 10px", borderRadius: '10px', minHeight: 20 }}>
                                    <Flexbox alignItems="center" justifyContent="space-between">
                                        <Typography style={{ opacity: '.7', fontSize: '16px' }}>
                                            {id_back ? String.shorten(id_back.name, 20)  : "Select a file to upload"}
                                        </Typography>

                                        <Typography className="blue-text" style={{ fontSize: '16px' }}>
                                            upload image
                                        </Typography>
                                    </Flexbox>
                                </div>
                                <input
                                    onChange={(e) => handleIDFileBack(e.target.files[0])}
                                    type="file"
                                    placeholder="Select a file to upload"
                                    style={{ position: 'absolute', top: 0, left: 0, opacity: 0 }}
                                />
                            </div>
                        </div>}
                        <Spacebox padding="10px" />
                        <Button className="fullwidth" style={{ borderRadius: '5px', backgroundColor: loading ? 'white' : '#242A3A', padding: '15px 0px' }} handleClick={() => handleIdSubmit()}>
                            {!loading && <span className="white-text">
                                Submit {govID}
                            </span>}
                            {loading && <Flexbox justifyContent={"center"}>
                                <DotPulse size={30} color="#242A3A" />
                            </Flexbox>}
                        </Button>
                    </div>}
                    {step === 3.5 && <div>
                        <Typography textAlign="center" style={{ fontSize: 25, fontWeight: 700 }}>
                            Addition Identity Check
                        </Typography>
                        <Spacebox padding="30px" />
                        <Spacebox padding="5px">
                            <small>Social Security Number</small>
                        </Spacebox>
                        <input
                            value={ssn}
                            onChange={(e) => setSsn(e.target.value)}
                            type="number"
                            placeholder="Enter your social security number"
                        />
                        <Spacebox padding="10px" />
                        <Button className="fullwidth" style={{ borderRadius: '5px', backgroundColor: loading ? 'white' : '#242A3A', padding: '15px 0px' }} handleClick={() => handleSubmitSSN()}>
                            {!loading && <span className="white-text">
                                Submit details
                            </span>}
                            {loading && <Flexbox justifyContent={"center"}>
                                <DotPulse size={30} color="#242A3A" />
                            </Flexbox>}
                        </Button>
                    </div>}
                    {step === 4 && <div>
                        <Typography textAlign="center" style={{ fontSize: 25, fontWeight: 700 }}>
                            How to record the video selfie
                        </Typography>
                        <Spacebox padding="30px" />
                        <Flexbox alignItems="center">
                            <Flexbox justifyContent="center" alignItems="center" style={{ width: 50, height: 50, background: '#f8f8f8', borderRadius: '1000px' }}>
                                <div style={{ width: 20, height: 20, borderRadius: '1000px', background: '#ff000080' }}></div>
                            </Flexbox>
                            <Spacebox padding="10px" />
                            <Typography>
                                Start recording the video selfie
                            </Typography>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <Flexbox alignItems="center">
                            <Avatar src="/assets/circular_motion.gif" alt="avatar" style={{ width: 50, height: 50 }} />
                            <Spacebox padding="10px" />
                            <Typography>
                                Make a circular motion with your nose
                            </Typography>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <Flexbox alignItems="center">
                            <Flexbox justifyContent="center" alignItems="center" style={{ width: 50, height: 50, background: '#f8f8f8', borderRadius: '1000px' }}>
                                <div style={{ width: 20, height: 20, borderRadius: '5px', background: '#ff000080' }}></div>
                            </Flexbox>
                            <Spacebox padding="10px" />
                            <Typography>
                                When done, press the stop button
                            </Typography>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <Flexbox alignItems="center">
                            <Flexbox justifyContent="center" alignItems="center" style={{ width: 50, height: 50, background: '#23b45113', borderRadius: '1000px' }}>
                                <Check className="green-text" style={{ color: '#23b451' }} />
                            </Flexbox>
                            <Spacebox padding="10px" />
                            <Typography>
                                Continue to upload video
                            </Typography>
                        </Flexbox>
                        <Spacebox padding="10px" />
                        <Button className="fullwidth" style={{ borderRadius: '5px', backgroundColor: '#242A3A', padding: '15px 0px' }} handleClick={() => handleStepFive()}>
                            <span className="white-text">
                                Record Video Selfie
                            </span>
                        </Button>
                    </div>}
                    {step === 5 && <div>
                        <ReactMediaRecorder
                            video
                            onStop={(blobUrl, blob) => {
                                setVideo(blob)
                            }}
                            render={({ status, startRecording, stopRecording, mediaBlobUrl, previewStream }) => (
                                <Flexbox alignItems="center" justifyContent="center" style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100%', background: '#fff' }}>
                                    <div>
                                        {(status === "idle" && prePreviewStream) && <VideoPrePreview stream={prePreviewStream} />}
                                        {status === "recording" && <VideoPreview stream={previewStream} status={status} />}
                                        {(status !== "recording" && status !== "idle") && <VideoResult url={mediaBlobUrl} />}
                                        <Spacebox padding="5px" />
                                        <Spacebox padding="0px 10px">
                                            <Typography textAlign="center">
                                                {videoCommand}
                                            </Typography>
                                        </Spacebox>
                                        <Spacebox padding="5px" />
                                        <Flexbox justifyContent="center">
                                            {status !== "recording" && <div onClick={() => {
                                                setPrePreviewStream("")
                                                handleRecording(startRecording, stopRecording)
                                            }} style={{ width: 70, height: 70, background: '#f8f8f8', borderRadius: '1000px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {status === "stopped" && <Replay className="red-text" style={{ fontSize: 35, }} />}
                                                {status !== "stopped" && <div style={{ width: 30, height: 30, borderRadius: '1000px', background: '#ff000080' }}></div>}
                                            </div>}
                                            {/* {status === "recording" && <div onClick={stopRecording} style={{ width: 70, height: 70, background: '#f8f8f8', borderRadius: '1000px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div style={{ width: 30, height: 30, borderRadius: '5px', background: '#ff000080' }}></div>
                                            </div>} */}
                                            {/* Space and the proceed button */}
                                            {status === "stopped" && <Spacebox padding="10px" />}
                                            {status === "stopped" && <div onClick={handleVideoUpload} style={{ width: 70, height: 70, background: '#23b45113', borderRadius: '1000px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Check className="green-text" style={{ color: '#23b451' }} />
                                            </div>}
                                        </Flexbox>
                                    </div>
                                </Flexbox>
                            )}
                        />
                    </div>}
                    {step === 6 && <div>
                        <Flexbox alignItems="center" justifyContent="center" style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100%', background: '#fff' }}>
                            <div className="mid">
                                <Flexbox justifyContent={"center"}>
                                    <DotPulse size={30} color="#242A3A" />
                                </Flexbox>
                                <Spacebox padding="10px" />
                                <Typography textAlign="center">
                                    Analysing video...
                                </Typography>
                            </div>
                        </Flexbox>
                    </div>}
                </Spacebox>
            </div>}
        </div>
    );
}

export default MetaMap;