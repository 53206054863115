import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import requests from "../handlers/requests";
import { Pulsar } from "@uiball/loaders";
import Flexbox from "../components/Flexbox";
import Toast from "../components/Toast";
import CustomGrid from "../components/styles/Grid";
import Spacebox from "../components/styles/Spacebox";
import Button from "../components/styles/Button";
import { updateuser } from "../features/users";
import { Typography } from "@mui/material";
import cookies from "../utilities/Cookies";

const Profile = () => {

    const mode = useSelector(state => state.darkmode.value)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [user, setUser] = useState(null)
    

    const dispatch = useDispatch()

    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")

    const handleClick = () => {
        if (firstname !== "" && lastname !== "" && phone !== "") {
            setIsLoading(true)
            requests.makePost('/api/user', { lastname, firstname, email, phone }, setOpen, setSeverity, setToastMsg, setIsLoading,
                (res) => {
                    const userObj = res.data
                    dispatch(updateuser(userObj))
                    cookies.setCookies("user", JSON.stringify(userObj), 3)
                },
                null
            )
        } else {
            setToastMsg("Invalid data type")
            setSeverity("error")
            setOpen(true)
        }
    }

    const getUser = () => {
        requests.makeGet('/api/user', setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                setUser(res.data)
                setEmail(res.data.email)
                setPhone(res.data.phone ?? "")
                setFirstname(res.data.firstname ?? "")
                setLastname(res.data.lastname ?? "")
            }
        )
    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <div className="profile-page">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            {loading && (
                <Flexbox justifyContent="center" alignItems="center" style={{ height: '50vh' }}>
                    <div className="small" style={{ textAlign: "center" }}>
                        <Flexbox justifyContent="center"><Pulsar size={40} speed={0.9} color={mode.dark ? "white" : "black"} /></Flexbox>
                    </div>
                </Flexbox>
            )}
            {user && (
                <div className="bg" style={{padding: '20px', borderRadius: '20px'}}>
                    <Typography>
                        Profile
                    </Typography>
                    <Spacebox padding="5px" />
                    <CustomGrid grid="2" gap="10px">
                        <div>
                            <small>First Name</small>
                            <Spacebox padding="5px" />
                            <input
                                type="text"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                placeholder="First name"
                            />
                        </div>
                        <div>
                            <small>Last Name</small>
                            <Spacebox padding="5px" />
                            <input
                                type="text"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                placeholder="Last name"
                            />
                        </div>
                    </CustomGrid>
                    <Spacebox padding="10px" />
                    <div>
                        <small>Email</small>
                        <Spacebox padding="5px" />
                        <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            disabled
                        />
                    </div>
                    <Spacebox padding="10px" />
                    <div>
                        <small>Phone</small>
                        <Spacebox padding="5px" />
                        <input
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Phone number"
                        />
                    </div>
                    <Spacebox padding="10px" />
                    <Button
                        backgroundColor="var(--primary)"
                        color="white"
                        borderRadius="10px"
                        padding="15px 0px"
                        handleClick={handleClick}
                        className="fullwidth"
                    >
                        {!isLoading && "Update Profile"}
                        {isLoading && (
                            <Flexbox justifyContent="center" alignItems="center">
                                <Pulsar size={13} color={mode.dark ? "black" : "white"} />
                            </Flexbox>
                        )}
                    </Button>
                </div>
            )}
        </div>
    );
}

export default Profile;