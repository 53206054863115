import useFetch from "../hooks/useFetch";
import { useSelector } from "react-redux";
import { Skeleton, Typography } from "@mui/material";
import Flexbox from "../components/Flexbox";
import Spacebox from "../components/styles/Spacebox";
import Button from "../components/styles/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import requests from "../handlers/requests";
import Toast from "../components/Toast";

const Dashboard = () => {
    const { data: courses, isLoading: loading, error } = useFetch('/api/courses')
    const { data: user_courses } = useFetch('/api/user/courses')

    const navigate = useNavigate()

    
    const mode = useSelector(state => state.darkmode.value)
    const [user, setUser] = useState(null)
    
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [, setLoading] = useState(false)


    const checkIfUnlock = (course, courses) => {
        // checks if a course is available in target user's available courses
        // courses is an array of objects which holds all user's courses id they have registed/purchased
        // loop throught the courses array of objects and return true if course id is found in the courses array of object. else return false

        for (let x in courses) {
            if (courses[x].id === course)
                return true
        }
        return false
    }

    const getUser = () => {
        requests.makeGet('/api/user', setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                setUser(res.data)
            }
        )
    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <div className="dashboard">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Typography sx={{ fontSize: '25px' }}>
                Classroom
            </Typography>
            <small>
                Youtube Profit Challenge
            </small>
            <Spacebox padding="10px" />
            {loading && <div>
                <Skeleton width="100%" height="400px" variant="rounded" />
                <Spacebox padding="10px" />    
                <Skeleton width="100%" height="400px" variant="rounded" />
            </div>}
            {error && <Flexbox justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                <div className="mid" style={{ textAlign: "center" }}>
                    <Spacebox padding="50px" />
                    <img src="/assets/broken-link.png" alt="" style={{ width: '50px', filter: mode.dark ? "invert(1)" : "invert(0)" }} />
                    <Typography variant="h6" className="bold"> There seems to be a problem</Typography>
                    {error.includes("Unauthorised") && (
                        <Button
                            backgroundColor="transparent"
                            color={mode.dark ? "white" : "black"}
                            borderRadius="10px"
                            padding="15px 0px"
                            handleClick={() => {
                                window.location.reload()
                            }}
                            className="fullwidth"
                        >
                            Refresh App
                        </Button>
                    )}
                </div>
            </Flexbox>}
            {(courses && user_courses) && (
                <div>
                    {courses.map((course, index) => (
                        <div key={index}>
                            <div style={{ background: `url(${course.image})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '200px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundBlendMode: checkIfUnlock(course.id, user_courses) ? 'normal' : 'darken', backgroundColor: '#00000090', borderRadius: '20px 20px 0px 0px' }}>
                                {!checkIfUnlock(course.id, user_courses) && (
                                    <img alt="locked" src="/svgs/lock.svg" style={{fontSize: '50px'}} />
                                )}
                            </div>
                            <div className="bg" style={{ padding: '20px', borderRadius: '0px 0px 20px 20px' }}>
                                <Typography style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    {course.title}
                                </Typography>
                                <Spacebox padding="5px" />
                                <span>
                                    {course.description}
                                </span>
                                <Spacebox padding="10px" />
                                {checkIfUnlock(course.id, user_courses) && <Button
                                    backgroundColor="var(--primary)"
                                    color="white"
                                    borderRadius="10px"
                                    padding="15px 0px"
                                    handleClick={() => {
                                        navigate(`/dashboard/course/${course.id}`)
                                    }}
                                    className="fullwidth"
                                >
                                    Access Program
                                </Button>}
                                {!checkIfUnlock(course.id, user_courses) && <Button
                                    backgroundColor="var(--primary)"
                                    color="white"
                                    borderRadius="10px"
                                    padding="15px 0px"
                                    handleClick={() => {
                                        window.location.href = `${course.purchase_url}?cid=${course.id}&uid=${user.id}`
                                    }}
                                    className="fullwidth"
                                >
                                    Purchase Program
                                </Button>}
                            </div>
                            <Spacebox padding="10px" />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Dashboard;