import { useState } from "react";
import Spacebox from "../../components/styles/Spacebox";
import Mode from "../../components/styles/Mode";
import Button from "../../components/styles/Button";
import requests from "../../handlers/requests";
import Toast from "../../components/Toast";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

const Admin = () => {


    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [loading, setLoading] = useState(false)

    // courses states
    const [courseTitle, setCourseTitle] = useState('')
    const [courseDesc, setCourseDesc] = useState("")
    const [cImage, setCImage] = useState("")
    const [cPurchaseUrl, setCPurchaseUrl] = useState("")

    // lesson states
    const [lTitle, setLTitle] = useState('')
    const [lDesc, setLDesc] = useState("")
    const [video, setVideo] = useState("")
    const [order, setOrder] = useState("")
    const [courseId, setCourseId] = useState("")

    // quill toolbar
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6] }],
        [{ 'color': [] }, { 'background': [] }],
    ]


    const addCourse = () => {
        setLoading(true)
        const data = {
            title: courseTitle,
            description: courseDesc,
            image: cImage,
            purchase_url: cPurchaseUrl
        }
        requests.makePost('/api/course/add', data, setOpen, setSeverity, setToastMsg, setLoading,
            (_) => {

            },
            "Course added"
        )
    }

    const addLesson = () => {
        setLoading(true)
        const data = {
            title: lTitle,
            description: lDesc,
            video: video,
            order: parseFloat(order)
        }
        requests.makePost(`/api/course/${courseId}/lesson/add`, data, setOpen, setSeverity, setToastMsg, setLoading,
            (_) => {
                setLTitle("")
                setLDesc("")
                setVideo("")
            },
            "Lesson added"
        )
    }


    return (
        <Mode>
            <div className="admin-page small margin-auto">
                <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>

                <Spacebox padding="20px" />
                <div className="course-section">
                    <h4>Add Course</h4>
                    <input
                        type="text"
                        placeholder="Course Title"
                        value={courseTitle}
                        onChange={(e) => setCourseTitle(e.target.value)}
                    />
                    <Spacebox padding="10px" />
                    <textarea
                        type="text"
                        placeholder="Course Description"
                        value={courseDesc}
                        onChange={(e) => setCourseDesc(e.target.value)}
                    ></textarea>
                    <Spacebox padding="10px" />
                    <input
                        type="text"
                        placeholder="Course Image"
                        value={cImage}
                        onChange={(e) => setCImage(e.target.value)}
                    />
                    <Spacebox padding="10px" />
                    <input
                        type="text"
                        placeholder="Course Purchase URL"
                        value={cPurchaseUrl}
                        onChange={(e) => setCPurchaseUrl(e.target.value)}
                    />
                    <Spacebox padding="10px" />
                    <Button
                        backgroundColor="var(--primary)"
                        color="white"
                        borderRadius="10px"
                        padding="15px 0px"
                        handleClick={addCourse}
                        className="fullwidth"
                    >
                        {loading ? "Please wait..." : "Add Course"}
                    </Button>
                </div>
                <Spacebox padding="20px" />
                <div className="lesson-section">
                    <h4>Add Lesson</h4>
                    <input
                        type="text"
                        placeholder="Lesson Title"
                        value={lTitle}
                        onChange={(e) => setLTitle(e.target.value)}
                    />
                    <Spacebox padding="10px" />
                    <ReactQuill
                        theme="snow"
                        placeholder="Lesson Description"
                        modules={{ toolbar: toolbarOptions }}
                        value={lDesc}
                        onChange={setLDesc}
                    />
                    <Spacebox padding="10px" />
                    <input
                        type="text"
                        placeholder="Course ID"
                        value={courseId}
                        onChange={(e) => setCourseId(e.target.value)}
                    />
                    <Spacebox padding="10px" />
                    <input
                        type="number"
                        placeholder="Lesson Order"
                        value={order}
                        onChange={(e) => setOrder(e.target.value)}
                    />
                    <Spacebox padding="10px" />
                    <input
                        type="text"
                        placeholder="Lesson Video iFrame"
                        value={video}
                        onChange={(e) => setVideo(e.target.value)}
                    />
                    <Spacebox padding="2px" />
                    <small>Enter "null" if lesson doesn't have a video</small>
                    <Spacebox padding="10px" />
                    <Button
                        backgroundColor="var(--primary)"
                        color="white"
                        borderRadius="10px"
                        padding="15px 0px"
                        handleClick={addLesson}
                        className="fullwidth"
                    >
                        {loading ? "Please wait..." : "Add Lesson"}
                    </Button>
                </div>
                <Spacebox padding="20px" />
            </div>
        </Mode>
    );
}

export default Admin;