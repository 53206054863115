import cookies from "../utilities/Cookies";
import Topbar from "./Topbar";

const Footer = () => {
    const user_stringify = cookies.getCookies("user")


    return (
        <div className="footer">
            {user_stringify.length > 5 && (
                <div className="hide-on-large-only">
                    <Topbar />
                </div>
            )}
        </div>
    );
}

export default Footer;