import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Mode from "../components/styles/Mode";
import Flexbox from "../components/Flexbox";
import { Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import Button from "../components/styles/Button";
import Toast from "../components/Toast";
import requests from "../handlers/requests";
import cookies from "../utilities/Cookies";
import { updatedarkmode } from "../features/mode";

const Onboarding = ({ title }) => {
    document.querySelector("title").innerText = title
    const dispatch = useDispatch()

    const industries = [
        "Art, Culture, Creative (services and supplies)",
        "Automotive and Aftermarket",
        "Beauty, Personal Care and Grooming",
        "Consumer Electronics and Appliances",
        "Digital Marketing and Advertising",
        "Digital Media Influencing & Content Creation (blog, website, forum, etc.)",
        "Education, Training and Professional Development",
        "Fashion - Clothes, Shoes and Apparel",
        "Financial & Legal Services",
        "Food and Beverage",
        "Home & Garden Furnishings and Design",
        "Human Resources and Talent Management",
        "Industrial Manufacturing (equipment and supplies)",
        "Information Technology and Software",
        "Jewelry and Accessories",
        "Logistics and Transportation",
        "Luxury Goods and Accessories",
        "Niche Goods & Accessories",
        "Online Gaming and Gambling",
        "Outdoor Recreation and Sporting",
        "Personalized and Customized Goods",
        "Pets (products & services)",
        "Professional and Business Consulting",
        "Professional Healthcare and Medical (Supplies, Equipment & Services)",
        "Real Estate and Construction",
        "Travel, Tourism, and Leisure",
        "Vaping and Smoking Accessories",
        "Other"
    ]

    const [industry, setIndustry] = useState(industries[0])
    const [injury, setInjury] = useState("")
    const [fullname, setFullname] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [loading, setLoading] = useState(false)

    const handleClick = () => {
        setLoading(true)
        requests.makePost(`/api/lead/add`, { fullname, email, phone, industry, injury }, setOpen, setSeverity, setToastMsg, setLoading,
            (_) => {
                window.location.replace('https://www.movesmethod.com/toolkit-orderform61696639')
            },
            null
        )
    }

    useEffect(() => {
        //  get url params
        const UrlParams = new URLSearchParams(window.location.search)
        setFullname(UrlParams.get("n"))
        setEmail(UrlParams.get("e"))
        setPhone(UrlParams.get("p"))

        // make dark mode
        const obj = { dark: true }
        dispatch(updatedarkmode(obj))
        cookies.setCookies("mode", "dark", 20)

        // eslint-disable-next-line
    }, [])



    return (
        <Mode>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <div className="on-boarding" style={{backgroundImage: 'url(/assets/bg.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                <Flexbox style={{ minHeight: '100vh', padding: '0px 20px' }} alignItems="center" justifyContent="center" >
                    <div className="mid">
                        <Typography>
                            What industry do you work in?
                        </Typography>
                        <small>
                            Your answer will help us personalise your experience
                        </small>
                        <Spacebox padding="5px" />
                        <select
                            value={industry}
                            onChange={(e) => setIndustry(e.target.value)}
                        >
                            {industries.map((val, index) => (
                                <option key={index} value={val}>
                                    {val}
                                </option>
                            ))}
                        </select>
                        <Spacebox padding="10px" />
                        <Typography>
                            What injury/restrictions are you dealing with ?
                        </Typography>
                        <Spacebox padding="5px" />
                        <input
                            type="text"
                            value={injury}
                            onChange={(e) => setInjury(e.target.value)}
                            placeholder="What injury/restrictions are you dealing with?"
                        />
                        <Spacebox padding="20px" />
                        <Button
                            backgroundColor="var(--primary)"
                            color="white"
                            borderRadius="10px"
                            padding="15px 0px"
                            handleClick={handleClick}
                            className="fullwidth"
                        >
                            {loading && "Please wait..."}
                            {!loading && "Submit"}
                        </Button>
                    </div>
                </Flexbox>
            </div>
        </Mode>
    );
}

export default Onboarding;