import { useNavigate } from "react-router-dom";
import Flexbox from "./Flexbox";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

const Topbar = () => {
    const navigate = useNavigate()

    const mode = useSelector(state => state.darkmode.value)

    const checkUrl = () => {
        let path = window.location.pathname
        if (path === "/") {
            return false
        } else if (path.includes('/enter-password')) {
            return false
        } else if (path.includes("/create-account")) {
            return false
        } else if (path.includes("/login")) {
            return false
        } else {
            return true
        }
    }

    const mobile_menues = [
        {
            title: "Home",
            icon: "/svgs/home.svg",
            path: "/dashboard",
            action: () => navigate('/dashboard')
        },
        {
            title: "Notifications",
            icon: "/svgs/bell.svg",
            path: "/dashboard/notifications",
            action: () => navigate('/dashboard/notifications')
        },
        {
            title: "Settings",
            icon: "/svgs/settings.svg",
            path: "/dashboard/settings",
            action: () => navigate('/dashboard/settings')
        },
        {
            title: "Profile",
            icon: "/svgs/user.svg",
            path: "/dashboard/profile",
            action: () => navigate('/dashboard/profile')
        },
    ]
    return (
        <div className="top-bar">
            <div>
                {checkUrl() && <>
                    <Flexbox style={{ borderRadius: '0px', background: mode.dark ? '#000' : "#fff", padding: '20px' }} alignItems="center" className="hide-on-large-only" justifyContent="space-between">
                        {mobile_menues.map((menu, index) => (
                            <div key={index} onClick={menu.action} style={{ cursor: "pointer", marginRight: "0px" }}>
                                <Flexbox alignItems="center" justifyContent={"center"}>
                                    <img src={menu.icon} alt={menu.title} style={isMobile ? { width: 20, opacity: menu.path === window.location.pathname ? 1 : .5, filter: mode.dark ? 'invert(1)' : 'invert(0)' } : {}} />
                                </Flexbox>
                            </div>
                        ))}
                    </Flexbox>
                </>}
            </div>
        </div>
    );
}

export default Topbar;